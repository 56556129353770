import './App.css';

function App() {
  return (
    <div className="App">
      <p class="black">YOU'LL NEVER EVOLVE</p>
      <p>I KNOW I CAN CHANGE</p>
      <p class="black">WE ARE NOT ENOUGH</p>
      <p>WE ARE NOT THE SAME</p>
      <p class="black">YOU DON'T HAVE THE HEART</p>
      <p>YOU DON'T HAVE THE STRENGTH</p>
      <p class="black">YOU DON'T HAVE THE WILL</p>
      <p>YOU DON'T HAVE THE FAITH</p>
      <p class="black">YOU'LL NEVER BE LOVED</p>
      <p class="black">YOU'LL NEVER BE SAFE</p>
      <p class="black">MIGHT AS WELL GIVE UP</p>
      <p>NOT RUNNING AWAY</p>
      <p class="black">YOU DON'T HAVE THE GUTS</p>
      <p>YOU'RE THE ONE AFRAID</p>
      <p class="black">I'M THE ONE IN CHARGE</p>
      <p>I'M TAKING THE</p>
      <p class="black">NO...</p>
      <p>I'M TAKING THE</p>
      <p><br />reins.</p>
    </div>
  );
}

export default App;
